.site-header {
  background-image: url('../../images/heading.jpg');
  background-size: cover;
  background-position: 50% 50%;
  color: #fff;
  text-align: center;
  line-height: 3em;
  padding-top: 9em;
  padding-bottom: 10em;
}

.site-header__heading {
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.site-header__heading-p1 {
  text-transform: uppercase;
  font-size: 12em;
  font-weight: 700;
  line-height: 1;
  font-family: PT Sans, sans-serif;
}

.site-header__heading-p2 {
  font-family: Lora, serif;
  font-style: italic;
  font-weight: 400;
  font-size: 2.7em;
  display: block;
  position: relative;
}

@media (max-width: 959px) {
  .site-header {
    padding-top: 5em;
    padding-bottom: 6em;
  }

  .site-header__heading-p1 {
    font-size: 10em;
  }

  .site-header__heading-p2 {
    font-size: 2.2em;
  }
}

@media (max-width: 599px) {
  .site-header {
    padding-bottom: 5em;
  }

  .site-header__heading-p1 {
    font-size: 7em;
  }

  .site-header__heading-p2 {
    font-size: 1.6em;
  }
}
