.trail-tile {
	display: block;
	height: 0;
	padding-bottom: 100%;
	position: relative;
	text-decoration: none;
	background-size: cover;
	background-position: 50% 50%;
}

.trail-tile__wrapper {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
  padding: 20px;
  color: #fff;	
}

.trail-tile__name {
	display: block;
	text-transform: uppercase;
	font-weight: 700;
	font-family: PT Sans, sans-serif;
	font-size: 2em;
	text-shadow: 0 0 5px rgba(0,0,0,0.75);
}

.trail-tile__distance {
	display: block;
	font-family: Lora, serif;
	font-style: italic;
	font-weight: 400;
	text-shadow: 0 0 3px rgba(0,0,0,0.5);
	position: absolute;
	bottom: 25px;
	left: 20px;
	right: 20px;
}

.trail-tile__progress-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5px;
	background-color: rgba(0,0,0,0.5);
}

.trail-tile__progress-inner {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 5px;
	background-color: orange;
	font-size: 0;
}

@media (max-width: 599px) {
	.trail-tile__distance {
		font-size: 1.2em;
  }
}