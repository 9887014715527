.back-link-section {
	margin-top: -40px;
	margin-bottom: 60px;
}

.back-link {
	display: inline-block;
	padding-left: 25px;
	position: relative;
	font-family: PT Sans, sans-serif;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 1.2em;
	color: #fff;
}

.back-link:before {
  content: "";
  position: absolute;
  left: 3px;
  top: 50%;
  margin-top: -9px;
  width: 14px;
  height: 14px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  transform: rotate(-45deg);
  transform-origin: 50% 50%;
}

@media (max-width: 599px) {
  .back-link-section {
		margin-top: -30px;
		margin-bottom: 40px;
	}

  .back-link {
		padding-left: 20px;
  }

  .back-link:before {
    margin-top: -6px;
    width: 10px;
    height: 10px;
  }
}